import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "315px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8d6594bcabde445364752fedc6330d1e/a6688/the-alchemist.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150.99999999999997%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAP/xAAXAQEBAQEAAAAAAAAAAAAAAAACAwQF/9oADAMBAAIQAxAAAAGJv1xdDClRae2BMlVF/8QAHBAAAgICAwAAAAAAAAAAAAAAAREAAhIxAyEy/9oACAEBAAEFAgAkJfdEnL+uMoZiX3l1lLFn/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQIQ/9oACAEDAQE/AWwxkc//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8BtRlH/8QAGhAAAgIDAAAAAAAAAAAAAAAAASEAAhAwMf/aAAgBAQAGPwIKcy6nR//EAB0QAQADAAEFAAAAAAAAAAAAAAEAESExEGFxobH/2gAIAQEAAT8hVk4naQBkrJUVLjY+CZilHhIgZvTCALyefqDYE//aAAwDAQACAAMAAAAQO8Py/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEQ/9oACAEDAQE/EKAZQc//xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhMf/aAAgBAgEBPxBRvE7dhy//xAAeEAEAAgICAwEAAAAAAAAAAAABABEhMUFRgZHh8f/aAAgBAQABPxBobtnufmwZWaYI0W6KAwQGK3CafYcHISuDQ8RBs4KiW7tJQIRnF3L9vSXYBVUz/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The-Alchemist",
            "title": "The-Alchemist",
            "src": "/static/8d6594bcabde445364752fedc6330d1e/a6688/the-alchemist.jpg",
            "srcSet": ["/static/8d6594bcabde445364752fedc6330d1e/e07e9/the-alchemist.jpg 200w", "/static/8d6594bcabde445364752fedc6330d1e/a6688/the-alchemist.jpg 315w"],
            "sizes": "(max-width: 315px) 100vw, 315px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`⛰ What It's About`}</h2>
    <p>{`The book is about a boy who is a shepherd. Unlike most shepherds he can read and he always owns a book and he even uses the books as his pillows. He was destined to become a priest of a church but, he loved to travel. He wanted to explore the world so one day he decided to part ways from his family. He became a shepherd and with his sheep, he went on travelling. He used to talk to the sheep sometimes which led him to believe that there was a universal language that everyone could understand. The book places a lot of emphasis on 'Omens' which are the signs that god uses to communicate to the people who can't understand the universal language. `}<em parentName="p">{`The boy stations himself for a while inside the ruins of an old church and there is a Sycamore tree planted there`}</em>{`.  `}</p>
    <p>{`The boy, twice has the same dream  in which he is told that there is a great treasure awaiting him near the Pyramids. In Egypt. The boy lives in Spain, still the boy is determined to go to Egypt to find his treasure. He remembers a nearby town to which he had visited an year ago to sell some wool to a merchant. There, he had met the girl of the merchant and he had grown to like her. The girl herself couldn't read and the boy had amazed her by telling her stories of his adventures(mixed with stories he had read in books). He decided to visit that town and he also decided to visit a women who claimed that she could explain dreams. He visited her and explained his dreams to him. For payment, she asked for a tenth of the treasure, if he finds it. He doesn't believe the old woman and goes on to travel to meet the girl of the merchant. On the way, the boy meets an old man who is a King named Melchizedek. who tells him to sell his sheep, so as to travel to Egypt, and introduces the idea of a Personal Legend. Your Personal Legend "is what you have always wanted to accomplish. Everyone, when they are young, knows what their Personal Legend is." So the boy finally gets convinced and decides to go to Africa. The old king also gives the boy two stones named Urim and Thummim and tells him that these stones can be used to sense omens.  `}</p>
    <p>{`When the boy goes to Africa all of his money is stolen and he decides to go to a crystal shop. He works there for some time until he gets enough money to go to Egypt. Finally, one day he leaves to the desert. His companion is an Englishman who wants to become and Alchemist. They go on through the desert while there is a big tribal war going on. They reach an oasis to stay and there the boy meets a girl named Fatima. He instantly falls in love with her. Then one day he understands an omen telling him that one of the tribes are going to attack the oasis. He goes to the chief of the oasis and is told that the oases are neutral land never to be attacked. But the chief does entertain the boy and tells him that if the attack does happen by tomorrow, then he will die but if the attack does happen then the boy will get a gold coin for each attacker who dies. After this meeting with the chief the boy comes out of the chief's tent and there the Alchemist meets him with an interesting style. He says that if his head is still on his shoulders the next day's night, then he has to come meet him. Then they travel for some time and the boy sort of turns into the wind to save his life(he just got wind excited enough to blow so hard that he could not be seen).  `}</p>
    <p>{`In the end, the boy finally reaches the pyramids. The alchemist gives him bunch of gold. The alchemist also gives gold to a care taker. Then the boy starts digging in the pyramids. He doesn't find his treasure there. A few thieves come and beat him up and take his gold. The boy tells them the reason why he was there. Then one man there tells him not to believe in such things as he had also had a dream once about a treasure awaiting him in a church beside a Sycamore tree. Then the thieves leave and the boy understands that God had played a trick with him and his treasure was at the very place he had started. Then the boy returns to get his treasure and this time he finally finds his treasure.  `}</p>
    <h2>{`🔍 How I Discovered It`}</h2>
    <p>{`While browsing through the Kindle Store`}</p>
    <h2>{`🧠 Thoughts`}</h2>
    <p>{`This book is a sweet little mystical fantasy tale. The air of mystery around the alchemist and his introduction to the boy were my person highlights from this book.`}</p>
    <h2>{`🥰 Who Would Like It?`}</h2>
    <p>{`This book should appeal to a very wide demographic. If you like anticipating outcomes and like to read some very cleaver dialogue then this book is for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      